import React, { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styles from './creditEditPage.module.scss';
import { CommunityPage } from '../communityPage';
import Box from '@mui/material/Box';
import { CarbonCredit } from './carbonCredit';
import Image from '../../Image';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  CREATE_ADDRESS_LOCATION,
  CREATE_CARBON_CREDIT,
  DELETE_CARBON_CREDIT,
  EDIT_ADDRESS_LOCATION,
  EDIT_CARBON_CREDIT,
  SHOW_CARBON_CREDIT,
} from '../../../api';
import { useParams, useSearchParams } from 'react-router-dom';
import moment from 'moment/moment';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import { useNavigate } from 'react-router';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import userContext from '../../../context/User/userContext';
import AuthWrapper from 'components/AuthWarpper';

export const CreditEditPage = () => {
  const methods = useForm();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const [isAddGoals, setIsAddGoals] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [carbonCreditData, setCarbonCreditData] = useState(null);
  const [cancelModal, setCancelModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [companyId, setCompanyId] = useState(null);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [logoByProjectType, setLogoByProjectType] = useState(null);
  const [isRenderComponent, setIsRenderComponent] = useState(false);

  const { user } = useContext(userContext);
  const [createAddressLocation] = useMutation(CREATE_ADDRESS_LOCATION);
  const [editAddressLocation] = useMutation(EDIT_ADDRESS_LOCATION);
  const [createCarbon, { data: carbonCreateResult, error: carbonCreateError, reset: resetCreate }] =
    useMutation(CREATE_CARBON_CREDIT);
  const [editCarbon, { data: carbonEditResult, error: carbonEditError, reset: resetEdit }] =
    useMutation(EDIT_CARBON_CREDIT);
  const [deleteCarbon, { data: carbonDeleteResult, error: carbonDeleteError, reset: resetDelete }] =
    useMutation(DELETE_CARBON_CREDIT);
  const [getCarbonCreditById] = useLazyQuery(SHOW_CARBON_CREDIT);

  useEffect(() => {
    if (searchParams) {
      setCompanyId(searchParams.get('company'));
    }
  }, [searchParams]);

  // useEffect(() => {
  //   if (user && (!user?.roles_list || !JSON.parse(user.roles_list).includes('transparenterra'))) {
  //     navigate('/dashboard/marketplace');
  //   }
  // }, [user]);

  useEffect(() => {
    if (id && !!user?.id) {
      (async () => {
        const response = await getCarbonCreditById({
          variables: { id: id },
          fetchPolicy: 'network-only',
        });
        setCarbonCreditData(response.data?.showCarbonCreditById);
        setIsDraft(!!response.data?.showCarbonCreditById?.is_draft);
        setCompanyId(response.data?.showCarbonCreditById?.company_id);
      })();
    } else if (!id) {
      setIsRenderComponent(true);
    }
  }, [id, user]);

  const prepareData = (data, location) => {
    let goals = data.goals
      ?.filter((goal) => goal.checked)
      .map((goal) => ({
        name: goal.name,
        description: goal.description,
      }));
    let preparedData = {
      name: data.name,
      is_draft: isDraft,
      is_fcem_deposit_available: data.is_fcem_deposit_available,
      amount: parseFloat(data.amount),
      quantity: parseFloat(data.quantity),
      price: parseFloat(data.price),
      ext_project_id: data.ext_project_id,
      ext_credit_id: data.ext_credit_id,
      link: data.link,
      vintage_from: moment(data.vintage_from).year(),
      vintage_to: moment(data.vintage_to).year(),
      standard_id: data.standard_id,
      project_type_id: data.project_type_id,
      provider_id: data.provider_id,
      region_id: data.region_id,
      description: data.description,
      unit_id: data.unit_id,
      currency_id: data.currency_id,
      creditGoals: goals,
    };
    if (location) {
      preparedData.address_location_id = location.id;
    }
    return preparedData;
  };

  const createOrUpdateAddressLocation = async (addressLocationInput) => {
    if (carbonCreditData?.address_location_id) {
      let editAddressLocationData = await editAddressLocation({
        variables: {
          input: {
            address: addressLocationInput.address,
            id: carbonCreditData.address_location_id,
          },
        },
      });
      return editAddressLocationData.data.editAddressLocation;
    } else {
      let createAddressLocationData = await createAddressLocation({
        variables: {
          input: addressLocationInput,
        },
      });
      return createAddressLocationData.data.createAddressLocation;
    }
  };
  const onSubmit = async (data) => {
    setIsDraft(false);
    let addressLocationData = await createOrUpdateAddressLocation(data.location);
    let dataInput = prepareData(data, addressLocationData);
    createOrUpdateNewCarbonCredit(dataInput);
  };

  const onSubmitDraft = () => {
    setIsDraft(true);
    createOrUpdateNewCarbonCredit(prepareData(methods.getValues()));
  };

  const onDelete = () => {
    deleteCarbon({
      variables: { id: carbonCreditData.id },
    });
  };

  const createOrUpdateNewCarbonCredit = async (inputData) => {
    if (!!carbonCreditData) {
      await editCarbon({
        variables: { input: { ...inputData, id: carbonCreditData.id } },
      });
    } else {
      await createCarbon({
        variables: { input: { ...inputData, company_id: companyId } },
      });
    }
  };

  useEffect(() => {
    if (carbonCreateError || carbonEditError) {
      setOpenErrorAlert(carbonCreateError.message || carbonEditError.message);
    }
  }, [carbonCreateError, carbonEditError]);

  useEffect(() => {
    if (carbonDeleteError) {
      setDeleteModal(false);
      setOpenErrorAlert(carbonDeleteError.message);
    }
  }, [carbonDeleteError]);

  useEffect(() => {
    if (carbonCreateResult?.createCarbonCredit?.id) {
      navigate(`/dashboard/credit/${carbonCreateResult?.createCarbonCredit?.id}`);
    }
  }, [carbonCreateResult]);

  useEffect(() => {
    if (carbonEditResult?.editCarbonCredit?.id) {
      navigate(`/dashboard/credit/${carbonEditResult?.editCarbonCredit?.id}`);
    }
  }, [carbonEditResult]);

  useEffect(() => {
    if (carbonDeleteResult && companyId) {
      navigate(`/dashboard/company/${companyId}/store`);
    }
  }, [carbonDeleteResult, companyId]);

  return (
    <AuthWrapper reloadAfterLogin>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className={styles.container}>
          {isAddGoals ? (
            <CommunityPage
              setOpen={setIsAddGoals}
              save={() => setIsAddGoals(false)}
              saveButton
              saveButtonText="save"
            />
          ) : (
            <>
              <CarbonCredit
                logoByProjectType={logoByProjectType}
                setIsRenderComponent={setIsRenderComponent}
                isRenderComponent={isRenderComponent}
                setLogoByProjectType={setLogoByProjectType}
                key={isAddGoals}
                setIsAddGoals={setIsAddGoals}
                carbonCreditData={carbonCreditData}
              />
              <Box className={styles.buttons}>
                <button
                  type="button"
                  className={styles.buttonCancel}
                  onClick={() => setCancelModal(true)}
                >
                  Cancel
                </button>
                <div className={styles.carbonBtnGroup}>
                  {id && (
                    <button
                      type="button"
                      className={styles.buttonCancel}
                      onClick={() => setDeleteModal(true)}
                    >
                      <Image src={'/images/delete-blue.svg'} width={18} />
                      <div>Delete</div>
                    </button>
                  )}
                  {(carbonCreditData?.is_draft || !id) && (
                    <button
                      type="button"
                      className={styles.buttonCancel}
                      onClick={() => onSubmitDraft()}
                    >
                      Save as a draft
                    </button>
                  )}
                  <button type="submit" className={styles.buttonNext}>
                    <div>Publish</div>
                  </button>
                </div>
              </Box>
              <Modal
                BackdropProps={{
                  style: {
                    backgroundColor: 'white',
                    backdropFilter: 'blur(14px)',
                    background: 'rgba(250, 250, 250, 0.8)',
                  },
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                open={deleteModal}
                onClose={() => setDeleteModal(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
              >
                <Box className={styles.modal}>
                  <Box className={styles.modalTitle}>
                    Do you really want to delete the {carbonCreditData?.name} page?
                    <IconButton
                      aria-label="close"
                      onClick={() => setDeleteModal(false)}
                      className={styles.closeIcon}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <div className={styles.modalActions}>
                    <button
                      className={styles.leftBtn}
                      type="button"
                      onClick={() => setDeleteModal(false)}
                    >
                      CANCEL
                    </button>
                    <button className={styles.rightBtn} type="button" onClick={() => onDelete()}>
                      DELETE
                    </button>
                  </div>
                </Box>
              </Modal>
              <Modal
                BackdropProps={{
                  style: {
                    backgroundColor: 'white',
                    backdropFilter: 'blur(14px)',
                    background: 'rgba(250, 250, 250, 0.8)',
                  },
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                open={cancelModal}
                onClose={() => setCancelModal(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
              >
                <Box className={styles.modal}>
                  <Box className={styles.modalTitle}>
                    Do you really want to leave the this page?
                    <br />
                    All data will be saved {id ? '' : 'to drafts'}.
                    <IconButton
                      aria-label="close"
                      onClick={() => setCancelModal(false)}
                      className={styles.closeIcon}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <div className={styles.modalActions}>
                    <button className={styles.leftBtn} type="button" onClick={() => navigate(-1)}>
                      DON&apos;T SAVE AND EXIT
                    </button>
                    <button
                      className={styles.rightBtn}
                      type="button"
                      onClick={methods.handleSubmit(onSubmit)}
                    >
                      SAVE
                    </button>
                  </div>
                </Box>
              </Modal>
            </>
          )}
        </form>
      </FormProvider>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={openErrorAlert}
        onClose={() => setOpenErrorAlert(false)}
      >
        <Alert
          onClose={() => setOpenErrorAlert(false)}
          severity="error"
          sx={{ width: '100%', backgroundColor: '#D32F2F', color: '#ffffff' }}
        >
          {openErrorAlert}
        </Alert>
      </Snackbar>
    </AuthWrapper>
  );
};
