import React, { useEffect, useState } from 'react';
import styles from './CarbonItemPage.module.scss';
import {
  Box,
  ButtonBase,
  Hidden,
  InputBase,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { Button, openNotification } from 'tt-ui-lib/core';
import { useNavigate, useParams } from 'react-router';
import Image from '../../Image';
import DescriptionTab from './CarbonItemTabs/DescriptionTab';
import { getAvatarPathBySize } from '../../../utils/appUtils';
// import CustomerReviewsTab from './CarbonItemTabs/CustomerReviewsTab';
import 'react-indiana-drag-scroll/dist/style.css';
import 'swiper/css';
import 'swiper/css/pagination';
import { useMutation } from '@apollo/client';
import { CREATE_CERT_ORDER_WITH_DEPOSIT } from '../../../api';
import { DELETE_CARBON_CREDIT } from '../../../api';
import { AppAvatar } from '../../avatar';
import RemoveIcon from '@mui/icons-material/Remove';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useSelectedContext } from '../../../context/contextSelector';
import { SignInUpModalWrapper } from '../../Modals/SignInUpModal/SignInUpModal';
import PartialLoader from '../../layout/PartialLoader';
import DeleteItemModal from 'components/deleteItemModal';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SearchProduct from 'components/SearchProduct';

const TABS = [
  {
    label: 'Description',
  },
  {
    label: 'Customer reviews',
  },
];

let tooltipProps = {
  arrow: {
    sx: {
      color: '#999999',
    },
  },
  tooltip: {
    sx: {
      zIndex: 1,
      padding: '12px 20px',
      border: 'none',
      borderRadius: '12px',
      backgroundColor: '#999999',
    },
  },
};

const CarbonItemPage = ({ carbon, isSeller, loading, setLoading }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { user, selectedCompany } = useSelectedContext((context) => context.user);
  const [goals, setGoals] = useState(null);
  const [countValue, setCountValue] = useState(1);
  const [showMore, setShowMore] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [deleteCarbonModal, setDeleteCarbonModal] = useState(false);
  const [deleteCarbonCredit] = useMutation(DELETE_CARBON_CREDIT);
  const [selectedPage, setSelectedPage] = useState('Description');

  const [createOrderRequest] = useMutation(CREATE_CERT_ORDER_WITH_DEPOSIT);

  useEffect(() => {
    if (carbon?.creditGoals && goals === null) {
      setGoals(carbon?.creditGoals ?? []);
    }
  }, [carbon]);

  const clickForSale = () => {
    if (!user.id) openAuth();

    navigate(`/dashboard/credit/${id}/checkout/discount?count=${countValue}`);
  };

  const openAuth = () => {
    setOpenLoginModal(true);
  };

  const buyNow = () => {
    if (!user.id) openAuth();

    setLoading(true);

    const payload = {
      credit_id: id,
      amount: countValue * carbon?.quantity,
      unit: carbon?.unit?.name,
      fcem_discount: 0,
      fcem_deposit: 0,
      company_id: selectedCompany?.isCompanySelected
        ? selectedCompany?.selectedCompany?.id || undefined
        : undefined,
    };

    createOrderRequest({ variables: { input: payload } })
      .then((response) => {
        if (
          response.data?.reservationCarbonCredit &&
          response.data?.reservationCarbonCredit?.order_id
        ) {
          navigate(
            `/dashboard/credit/${id}/checkout?orderId=${response.data?.reservationCarbonCredit?.order_id}`
          );
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        openNotification({
          type: 'error',
          message:
            err?.message && err?.message.includes('status code 400')
              ? 'The requested amount exceeds the available stock'
              : err?.message
                ? `Error while reservation credit: ${err?.message}`
                : 'Error while reservation credit, try again',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const changeCountValue = (value) => {
    let maxQuantity = carbon?.amount || 1;

    if (`${value}`.includes('-')) {
      return;
    }

    if (value <= maxQuantity) {
      setCountValue(value);
    }
  };

  const handleChangePage = (event, newValue) => {
    setSelectedPage(newValue);
  };
  const handleChangePageSelect = (event) => {
    handleChangePage(event, event.target.value);
  };

  const deleteCarbon = async () => {
    const res = await deleteCarbonCredit({ variables: { id: carbon.id } });
    if (res.data && res.data.deleteCarbonCredit) {
      setDeleteCarbonModal(false);
      let companyId = carbon.company_id;
      navigate(`/dashboard/company/${companyId}/store`);
    } else if (res.errors && res.errors.length > 0) {
      openNotification({
        type: 'error',
        message: res.errors[0].message,
      });
      setDeleteCarbonModal(false);
    }
  };

  const scrollToGoalDescription = (goalId) => {
    const targetElement = document.getElementById(goalId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const backToPrevPage = () => {
    navigate('/dashboard/marketplace/list/product?activeTab=carbon');
  };
  console.log(1, carbon?.amount * carbon?.quantity, countValue * carbon?.quantity);
  return (
    <Box className={styles.pageContentBox}>
      {!isSeller ? (
        <Box className={styles.filterContent}>
          <Box className={styles.filterBar} sx={{ flexGrow: 1 }}>
            <ButtonBase
              className={styles.backBtn}
              sx={{
                '.MuiTouchRipple-root': {
                  display: 'none',
                },
              }}
              onClick={backToPrevPage}
            >
              <ArrowBackIosNewIcon fontSize="medium" style={{ color: '#212121' }} />
              <Typography className={styles.productButtonOutlineText}>Back</Typography>
            </ButtonBase>
            <SearchProduct user={user.id} />
            <Box className={styles.mobileFilterBar}>
              <ButtonBase
                className={styles.backBtn}
                sx={{
                  '.MuiTouchRipple-root': {
                    display: 'none',
                  },
                }}
                onClick={backToPrevPage}
              >
                <ArrowBackIosNewIcon fontSize="medium" style={{ color: '#212121' }} />
                <Typography className={styles.productButtonOutlineText}>Back</Typography>
              </ButtonBase>
              <Box className={styles.shoppingCart} onClick={() => navigate('/dashboard/cart')}>
                <Image src="/images/market-place/shopping-cart.svg" width={24} height={24} />
              </Box>
            </Box>
            <Box className={styles.shoppingCart} onClick={() => navigate('/dashboard/cart')}>
              <Image src="/images/market-place/shopping-cart.svg" width={24} height={24} />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className={styles.statusBlock}>
          <ButtonBase
            className={styles.backBtn}
            sx={{
              '.MuiTouchRipple-root': {
                display: 'none',
              },
            }}
            onClick={backToPrevPage}
          >
            <ArrowBackIosNewIcon fontSize="medium" style={{ color: '#212121' }} />
            <Typography className={styles.productButtonOutlineText}>Back</Typography>
          </ButtonBase>
        </Box>
      )}
      <Box className={styles.productContent}>
        <Box className={styles.productHeader}>
          <Box className={styles.companyInfo}>
            <AppAvatar
              userName={carbon?.company?.company_name}
              src={getAvatarPathBySize(carbon?.company?.logos, 'smallPath')}
              size="xl"
              nav={0}
            />
            <a
              href={`/dashboard/company/${carbon?.company_id}/store`}
              target="_blank"
              rel="noreferrer"
              onClick={(e) => e.stopPropagation()}
              style={{ color: 'inherit' }}
            >
              <Typography className={styles.companyName}>
                {carbon?.company?.company_name}
              </Typography>
            </a>
          </Box>
          {isSeller && (
            <Box className={styles.headerActions}>
              <Button
                type="default"
                className={styles.actionBtn}
                onClick={() =>
                  navigate(
                    `/dashboard/proposals/credit/edit/${carbon.id}?company=${carbon.company_id}&from=carbon`
                  )
                }
              >
                <Image src="/images/pen-icon.svg" height={18} width={18} />
              </Button>
              <Button
                type="default"
                className={styles.actionBtn}
                onClick={() => setDeleteCarbonModal(true)}
              >
                <Image src="/images/delete-icon.svg" height={18} width={18} />
              </Button>
            </Box>
          )}
        </Box>
        <Box className={styles.productInfoContainer}>
          <Box className={styles.productImages}>
            <Box className={styles.selectImage}>
              <Image
                src={
                  carbon?.projectType && carbon?.projectType?.logoPath
                    ? carbon?.projectType?.logoPath
                    : '/images/market-place/no_image.png'
                }
                className={styles.carbonImage}
              />
              <Box className={styles.favorites}>
                <Image src="/images/market-place/favorite.svg" width={20} />
              </Box>
            </Box>
          </Box>
          <Box className={styles.productInfo}>
            <Typography className={styles.productName}>{carbon?.name}</Typography>
            <Typography className={styles.productPrice}>
              {carbon?.currency?.symbol} {carbon?.price}{' '}
              <span>
                /{carbon?.quantity}
                {carbon?.unit?.name} ({carbon?.quantity} shareable {carbon?.unit?.name} of CO2)
              </span>
            </Typography>
            {carbon?.is_fcem_deposit_available && carbon?.unit?.name === 't' && (
              <Box className={styles.greenFlag}>
                <Typography className={styles.offerText}>
                  Exclusive Offer for the FCEM holders
                </Typography>
                <Typography className={styles.offerPrice}>
                  {carbon?.currency?.symbol} {Math.ceil(carbon?.price / 2)}{' '}
                  <span>
                    /{carbon?.quantity}
                    {carbon?.unit?.name}
                  </span>
                </Typography>
                <Typography className={styles.offerDiscountPrice}>With 50% Discount</Typography>
              </Box>
            )}

            <Box className={styles.productCountContainer}>
              {!isSeller && parseInt(carbon?.amount) > 0 && (
                <Box className={styles.productChangeCountBlock}>
                  <Image
                    onClick={() => changeCountValue(countValue >= 2 ? countValue - 1 : 1)}
                    src="/images/market-place/minus.svg"
                    width={14}
                    style={{ padding: '12px 0' }}
                  />
                  <TextField
                    value={countValue}
                    className={styles.productCountInput}
                    style={{ width: `${countValue.toString().length + 4}ch` }}
                    type="number"
                    onBlur={(e) => setCountValue(+e.target.value || 1)}
                    onChange={(e) => changeCountValue(+e.target.value)}
                  />
                  <Image
                    onClick={() => changeCountValue(countValue + 1)}
                    src="/images/market-place/plus.svg"
                    width={14}
                  />
                </Box>
              )}
              {parseInt(carbon?.amount) > 0 ? (
                <p className={styles.productAvailableCount} style={{ color: '#4CAF50' }}>
                  {carbon?.amount && carbon?.quantity && parseInt(carbon?.amount)}
                  {carbon?.unit?.name} available
                </p>
              ) : (
                <p className={styles.productAvailableCount} style={{ color: '#d32f2f' }}>
                  Not available now
                </p>
              )}
            </Box>
            <Box className={styles.carbonInfoBlock}>
              <Typography className={styles.parameters}>
                Vintage:{' '}
                <span>
                  {carbon?.vintage_from}-{carbon?.vintage_to}
                </span>
              </Typography>
              <Typography className={styles.parameters}>
                Standard: <span>{carbon?.standard?.name}</span>
              </Typography>
              <Typography className={styles.parameters}>
                Project Type: <span>{carbon?.projectType?.name}</span>
              </Typography>
              <Box className={styles.carbonGoals}>
                {goals?.map(
                  (item, index) =>
                    (showMore || index < 4) && (
                      <Tooltip
                        slotProps={tooltipProps}
                        key={item.name}
                        title={item.name.replaceAll('_', ' ').toUpperCase()}
                        placement="top"
                        arrow
                      >
                        <Box
                          sx={{ cursor: 'pointer' }}
                          onClick={() => scrollToGoalDescription(item.id)}
                        >
                          <Image
                            key={item.name}
                            src={`/images/company/${item.name}.svg`}
                            width={28}
                            height={28}
                            style={{ borderRadius: '4px' }}
                          />
                        </Box>
                      </Tooltip>
                    )
                )}
                {goals?.length - 4 > 0 && !showMore ? (
                  <button
                    type="button"
                    onClick={() => setShowMore(true)}
                    className={styles.moreData}
                  >
                    +{goals?.length - 4}
                  </button>
                ) : goals?.length - 4 > 0 && showMore ? (
                  <button
                    type="button"
                    onClick={() => setShowMore(false)}
                    className={styles.moreData}
                  >
                    <RemoveIcon />
                  </button>
                ) : null}
              </Box>
            </Box>

            {!isSeller && parseInt(carbon?.amount) > 0 && (
              <Box className={styles.productButtonGroups}>
                <Button
                  type="primary"
                  disabled={
                    !user.id ||
                    !carbon.amount > 0 ||
                    carbon?.amount * carbon?.quantity < countValue * carbon?.quantity
                  }
                  onClick={() => buyNow()}
                >
                  BUY NOW {carbon?.currency?.symbol} {carbon?.price * countValue}
                </Button>

                {carbon?.is_fcem_deposit_available &&
                  carbon?.unit?.name === 't' &&
                  user.id &&
                  carbon.amount > 0 && (
                    <ButtonBase
                      onClick={() => clickForSale()}
                      type="button"
                      className={styles.productButtonOutline}
                    >
                      <Typography className={styles.productButtonOutlineText}>
                        Special price
                      </Typography>
                      {carbon?.is_fcem_deposit_available && (
                        <ArrowForwardIosIcon style={{ color: 'white' }} fontSize={'small'} />
                      )}
                    </ButtonBase>
                  )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box className={styles.tabsContainer}>
        <Hidden mdDown>
          <Tabs
            TabIndicatorProps={{ style: { backgroundColor: '#143471' } }}
            value={selectedPage}
            variant="scrollable"
            onChange={handleChangePage}
            className={styles.tabs}
            sx={{ '.MuiTabs-flexContainer': { justifyContent: 'start', gap: '40px' } }}
          >
            {TABS.map((item) => (
              <Tab
                key={item.label}
                value={item.label}
                label={`${item.label}`}
                className={[styles.tabItem, selectedPage === item.label ? styles.activeItem : null]}
              />
            ))}
          </Tabs>
        </Hidden>
        <Hidden mdUp>
          <Select
            onChange={handleChangePageSelect}
            value={selectedPage}
            size="medium"
            className={styles.topSelect}
            sx={{ '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
          >
            {TABS.map((item) => (
              <MenuItem value={item.label} key={item.label}>
                <span className={styles.topMenuItem}>{item.label}</span>
              </MenuItem>
            ))}
          </Select>
        </Hidden>
      </Box>
      <Box className={styles.productInformationContainer}>
        {selectedPage === 'Description' ? (
          <DescriptionTab carbon={carbon} goals={goals} />
        ) : selectedPage === 'Customer reviews' ? (
          /*<CustomerReviewsTab />*/
          <Box className={styles.comingSoonBlock}>
            <img src="/images/comingSoon.svg" alt="comingSoon" />
          </Box>
        ) : null}
      </Box>
      {deleteCarbonModal && (
        <DeleteItemModal
          title="Do you want to delete this item?"
          open={deleteCarbonModal}
          handleClose={() => setDeleteCarbonModal(false)}
          deleteItem={deleteCarbon}
        />
      )}

      <SignInUpModalWrapper
        open={openLoginModal}
        closeModal={() => setOpenLoginModal(false)}
        reloadAfterLogin
      />

      <PartialLoader loading={loading} isLightContainer />
    </Box>
  );
};

export default CarbonItemPage;
